import {
  AdjustmentsHorizontalIcon,
  CircleStackIcon,
  ClipboardDocumentIcon,
  CreditCardIcon,
  FilmIcon,
  InboxStackIcon,
  PlayCircleIcon,
  QueueListIcon,
  RectangleStackIcon,
  ShoppingBagIcon,
  UsersIcon
} from "@heroicons/react/24/outline";

type NavIcon = React.FC<Parameters<typeof CircleStackIcon>[0]>;

export type NavItem = {
  name: string;
  href: string;
  icon: NavIcon;
  current: boolean;
  section: string;
};

const navItems: NavItem[] = [
  {
    name: "Workouts",
    href: "/workouts",
    icon: CircleStackIcon,
    current: false,
    section: "main"
  },
  {
    name: "Exercises",
    href: "/exercises",
    icon: RectangleStackIcon,
    current: false,
    section: "secondary"
  },
  {
    name: "Transitions",
    href: "/transitions",
    icon: ClipboardDocumentIcon,
    current: false,
    section: "secondary"
  },
  {
    name: "Providers",
    href: "/providers",
    icon: ClipboardDocumentIcon,
    current: false,
    section: "main"
  },
  {
    name: "Patients",
    href: "/patients",
    icon: UsersIcon,
    current: false,
    section: "main"
  },
  {
    name: "Orders",
    href: "/orders",
    icon: CreditCardIcon,
    current: false,
    section: "main"
  },
  {
    name: "Order Queue",
    href: "/queues",
    icon: QueueListIcon,
    current: false,
    section: "main"
  },
  {
    name: "Order Inventory",
    href: "/inventory",
    icon: InboxStackIcon,
    current: false,
    section: "main"
  },
  {
    name: "Products",
    href: "/products",
    icon: ShoppingBagIcon,
    current: false,
    section: "main"
  },
  {
    name: "Stitches",
    href: "/stitches",
    icon: FilmIcon,
    current: false,
    section: "secondary"
  },
  {
    name: "Videos",
    href: "/videos",
    icon: PlayCircleIcon,
    current: false,
    section: "secondary"
  },
  {
    name: "Renders",
    href: "/renders",
    icon: AdjustmentsHorizontalIcon,
    current: false,
    section: "secondary"
  }
];

export { navItems };
